import ReservarLink from 'components/link/ReservarLink';
import React, { Component } from 'react';

const aboutbg = require('assets/images/intiBackground/bg4.jpg');
const slogan = require('assets/images/inti-slogan.png');

class SectionReservar extends Component {
    render() {
        const { ...props } = this.props;
        return (
            <>  <section class="testimonial-section  style-two text-center"
                style={{ backgroundImage: "url(" + aboutbg + ")" }}>
                <div class="auto-container">
                    <div class="row">
                        <div className="col-xl-12 col-md-12">
                            <div class="sec-title light">
                                <div class="sub-title" style={{ color: "#c1940c" }}>Inti Kamari</div>
                                <br />

                                <img src={slogan} width="40%" alt="img" />

                            </div>
                            <div class="btn-box">
                                <ReservarLink />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}
export default SectionReservar; 