import React, { Component } from 'react';

class SectionNum extends Component {

    getHeader = () => {
        let datos = [];
        this.props.dataSource.detailCategoy.map((header, i) => {
            var classItem = this.props.dataSource.md ? this.props.dataSource.md : " col-md-3 col-lg-3";
            datos.push(
                <>
                    <div class={"service-block-one " + classItem}>
                        <div class="inner-box">
                            <div class="icon">
                                <span class={header.icon}>
                                </span>
                                <span class="round-shape">
                                </span>
                            </div>
                            <h3>{header.title}</h3>
                            <div class="text">{header.category}</div>
                        </div>
                    </div>
                </>
            );
        });
        return datos;
    }
    render() {
        const { ...props } = this.props;
        return (
            <>
                <br />  <br />
                <br />  <br /><br />
                <section class="home-page services-section-three style-two ">
                    <div class="auto-container">

                        <div class={this.props.dataSource.nocenter ?
                            "sec-title   "
                            : "sec-title   text-center"}>
                            <div class="sub-title text-white">{this.props.dataSource.subtitle}</div>

                            <h2>{this.props.dataSource.title}</h2>
                            {props.children}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
export default SectionNum;