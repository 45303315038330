import { filterData, FILTER_DEFS, images } from 'assets/data/data.source.galeria';
import ServiceFilter1 from 'components/common/service-filter1';
import CustomPage from 'components/page/CustomPage';
import React, { Component } from 'react';
import { rutas } from 'router/rutas';

const aboutbg = require('assets/images/intiBackground/bg4.jpg');

class Galeria extends Component {
    render() {
        return (
            <>
                <CustomPage  >
                    <ServiceFilter1
                        images={images}
                        headerImage={aboutbg}
                        titulo={rutas.galeria.label}
                        subtitulo={rutas.galeria.subtitle}
                        filterData={filterData}
                        FILTER_DEFS={FILTER_DEFS} />
                </CustomPage>
            </>
        )
    }
}
export default Galeria;