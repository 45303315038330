import { DetalleCountHomeDataSource } from 'assets/data/data.source';
import { ActividadesHomeDataSource, cataServiciosDataSource, ServiceExtraTabDataSource, ServiciosDataSource } from 'assets/data/data.source.home';
import 'assets/less/antMotionStyle.less';
import { AddressMap } from 'components/common/AddressMap';
import HomeSlider1 from 'components/home/home-slider1';
import Feature6 from 'components/less/Feature6';
import CustomPage from 'components/page/CustomPage';
import SectionNum from 'components/section/SectionNum';
import SectionTabs2 from 'components/section/SectionTabs2';
import VideoPopup2 from 'components/video/video-popup2';
import { enquireScreen } from 'enquire-js';
import React, { Component } from 'react';
import SectionNews from '../../components/section/SectionNews';
import SectionServices from '../../components/section/SectionTabs';
import SectionAbaut from './sections/SectionAbaut';
import SectionHeader from './sections/SectionHeader';
const videobg2 = require('assets/images/intiBackground/bg1.jpg');

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

class Index extends Component {
    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
        this.state = {
            current: 0,
            isMobile,
            show: true,
        };
    }

    componentDidMount() {
        this.setState({ isMobile: window.innerWidth <= 991 ? true : false });
    }

    onTitleClick = (_, i) => {
        const carouselRef = this.carouselRef.current.childRefs.carousel;
        carouselRef.goTo(i);
    };

    onBeforeChange = (_, newIndex) => {
        this.setState({
            current: newIndex,
        });
    };

    render() {
        return (
            <CustomPage isHome>
                <HomeSlider1 />
                <br />  <br />
                < SectionHeader id="seccion1" key="seccion1" />
                <br />  <br /><br /> <br />
                <SectionAbaut />  
                <SectionNum dataSource={ActividadesHomeDataSource} >
                    <Feature6
                        id="Feature6_0"
                        key="Feature6_0"
                        dataSource={DetalleCountHomeDataSource}
                        isMobile={this.state.isMobile}
                    />
                </SectionNum>
                <br /> 
                <AddressMap /> 
                <br /> <br /><br /> 
                <SectionNews dataSource={cataServiciosDataSource} />
                <SectionServices dataSource={ServiciosDataSource} />
                <br /> <br />
                <SectionTabs2
                    dataSource={ServiceExtraTabDataSource} />
                <br /> <br /><br /> 
                <VideoPopup2
                    imgBackground={videobg2}
                    header={''}
                    title1={'Inti Kamari'}
                    title2={''}
                    videoId={'uRSHZ8-wMTw'} />
            </CustomPage>
        )
    }
}
export default Index;