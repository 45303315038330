import ReservarLink from 'components/link/ReservarLink';
import React from 'react';
import { rutas } from 'router/rutas';
const wellcomebg1 = require('assets/images/intiResource/5.png');
const fondoService = require('assets/images/intiServicios/portada.png');

const sc1 = require('assets/images/intiServicios/instalaciones.png');
const sc2 = require('assets/images/intiServicios/actividades.png');
const sc3 = require('assets/images/intiServicios/voluntarios.png');

export const HeaderDataSource = {
    children: [
        {
            name: 'retiros',
            icon: 'flaticon-analysis',
            title: 'Hospedaje Consciente',
            description: (
                <>
                    Nos relacionamos en estado puro con nuestro entorno,
                    adaptando la naturaleza, cultura, espacio, gastronomía
                    a un descanso mindfulness de nuestros huéspedes.
                </>
            ),
            linklabel: 'Ver mas',
            href: rutas.apartamentos.href
        },
        {
            name: 'hospedaje',
            icon: 'flaticon-destination',
            title: 'Eventos, convenciones y retiros',
            description: (<>
                Abrimos las puertas de nuestro hogar a eventos sociales, capacitaciones empresariales o retiros de bienestar.
            </>),
            linklabel: 'Ver mas',
            href: rutas.retiros.href,
            style: { backgroundImage: "url(" + wellcomebg1 + ")", backgroundSize: 'cover' },

        },
        {
            name: 'comunidad',
            icon: 'flaticon-home-insurance',
            title: 'Vinculación con la comunidad',
            description: (
                <>
                    Nuestro compromiso está con la comunidad de Quillosisa y zonas aledañas de Santa Isabel, Azuay.
                </>
            ),
            linklabel: 'Ver mas',
            href: rutas.comunidad.href
        },
    ],
};

export const AbautDataSource = {
    name: 'abaut',
    title: '¿Quienes somos?',
    subtitle1: <>30 000 mts ² <br />de agricultura regenerativa y sustentable</>,
    subtitle2: '',
    description: (<>
        Inti Kamari (regalo del sol en kichwa) es un proyecto familiar que nace, en 2016, con la necesidad de brindar un espacio de desprendimiento, relajación y comodidad fuera de los límites de la cotidianidad citadina.<br />
        Nos basamos en los principios del hospedaje de naturaleza, alimentación consciente, ecoturismo y permacultura, para que nuestros huéspedes se reconecten con sus orígenes: la madre tierra.
    </>),
    description2: 'Más de dos mil personas nos han visitado en los últimos tres años, en unos ciento veinte encuentros y retiros. Nuestra fundación ha ofrecido un centenar de talleres, excursiones y brigadas médicas gratuitas a la comunidad.',
};

export const cataServiciosDataSource = {

    name: 'service',
    background: fondoService,
    title: 'Servicios',
    subtitle: 'Naturaleza & Servicios',
    headerChildren: [
        {
            category: 'Instalaciones',
            image: sc1,
            href: rutas.apartamentos.href,
            subtitle:
                <>
                    Se encuentran adecuadas para un descanso completo, inmersas en la naturaleza del Valle de Yunguilla. Cada espacio tiene su toque rústico, perfecto para sentirte aislado del ambiente citadino y sumergirte a plenitud en el silencio de Inti Kamari.
                </>,
        },
        {
            category: 'Actividades',
            image: sc2,
            href: rutas.retiros.href,
            subtitle:
                <>
                    Cada una de ellas se encuentran orientadas a fomentar tu desarrollo personal y amor a nuestra tierra. Desde yoga, recorridos por nuestra finca, tours de agroturismo, cabalgata, caminatas a zonas turísticas aledañas a Inti Kamari o nadar en nuestra Cocha Ancestral de Quillosisa.
                </>,

        },
        {
            category: 'Voluntarios',
            image: sc3,
            href: rutas.voluntarios.href,
            subtitle:
                <>
                    Porque también hemos sido viajeros y sabemos de esa búsqueda por un lugar para descansar, meditar y aprender. En Inti Kamari siempre requerimos de manos extra para todas las tareas del hogar y abrimos nuestras puertas durante la temporada de voluntariado, cuatro meses al año. Anímate a escribirnos.
                </>
        },
    ],
};

export const ServiciosDataSource = {
    detailCategoy: [
        {
            eventKey: 'first',
            category: 'Salones',
            icon: 'flaticon-collaboration',
            body: {
                title: 'Salones',
                subtitle: 'Inti Kamari',
                image: require('assets/images/intiSalas/salas.png'),
                description:
                    <>En diferentes puntos dentro de Inti Kamari, contamos con salas abrazadas bellamente por el verdor de la fauna y flora autóctonas de la zona. Ideales para yoga, meditación, eventos o simplemente para tomarse un respiro de aire fresco.
                    </>,
            }
        },
        {
            eventKey: 'second',
            category: 'Habitaciones',
            icon: 'flaticon-home-insurance',
            body: {
                title: 'Habitaciones',
                subtitle: 'Inti Kamari',
                image: require('assets/images/intiSalas/habitaciones.png'),
                description:
                    <>
                        Imagínate despertar rodeado de huertos orgánicos o bosques frutales. Una experiencia indescriptible la que te brindan nuestras habitaciones. Todas cuentan con los elementos necesarios para garantizar ese momento de relax y sueño.
                    </>,
            }
        },
        {
            eventKey: 'third',
            category: 'Biblioteca',
            icon: 'flaticon-notebook',
            body: {
                title: 'Biblioteca',
                subtitle: 'Inti Kamari',
                image: require('assets/images/intiSalas/biblioteca.png'),
                description:
                    <>
                        ¿Amantes de la lectura? Contamos con una amplia biblioteca que abarca diferentes géneros literarios, ideales para una lectura en cualquier momento del día. Escoge tu tomo favorito y viaja a través de una experiencia sensorial que solo un buen libro te puede brindar.
                    </>,
            }
        },
        {
            eventKey: 'fourth',
            category: 'Áreas Verdes',
            icon: 'flaticon-worldwide',
            body: {
                title: 'Áreas Verdes',
                subtitle: 'Inti Kamari',
                image: require('assets/images/intiSalas/verdes.png'),
                description:
                    <>
                        Son 30 000 mts² de agricultura regenerativa y sustentable las que rodean a Inti Kamari. Estas áreas verdes favorecen el bienestar físico y emocional de nuestros huéspedes, ya que todo nuestro hogar se encuentra rodeado de un verdor único. Son usados comúnmente para actividades físicas, de desprendimiento o para una caminata tranquila.
                    </>,
            }
        },
        {
            eventKey: 'fifth',
            category: 'Actividades',
            icon: 'flaticon-life-insurance',
            body: {
                title: 'Actividades',
                subtitle: 'Inti Kamari',
                image: require('assets/images/intiSalas/actividades.png'),
                description:
                    <>
                        Brindamos, además de nuestro hospedaje, una serie de actividades que van desde:<br />
                        - Yoga Matutino <br />
                        - Tours de Agroturismo por nuestro Laboratorio de Setas y Hongos<br />
                        - Comestibles<br />
                        - Caminatas a zonas turísticas aledañas<br />
                        - Cabalgata<br />
                        - Recorridos por nuestra finca<br /><br />
                        O simplemente lo que tú desees hacer, con respeto y cuidado a cada uno de nuestros espacios que cuidamos con mucho cariño.
                    </>,
            }
        },
        {
            eventKey: 'sixth',
            category: 'Alimentos',
            icon: 'flaticon-star',
            body: {
                title: 'Alimentos',
                subtitle: 'Inti Kamari',
                image: require('assets/images/intiSalas/alimentos.png'),
                description:
                    <>
                        Basamos nuestra alimentación dentro de la rama “consciente”, es decir, usamos ingredientes orgánicos, extraídos de nuestro huerto, que permiten una comida deliciosa y saludable. Contamos con menú vegetariano, vegano, menú regular con carne, pollo o pescado y de niños.
                    </>,
            }
        },
    ],
};

export const UbicacionDs = {
    title: 'Ubicación',
    subtitle: 'Inti Kamari',
    body: <>
        Nos encontramos en el hermoso valle sub tropical de Yunguilla, en la cordillera de los Andes.
        Un gran  paisaje montañoso y senderos naturales para caminar. Un clima agradable el año entero.
        Y a sólo una hora de Cuenca,  la tercera ciudad del Ecuador, y cuyo centro es patrimonio cultural de la humanidad.
    </>,

};



export const ActividadesHomeDataSource = {
    title: 'Crece, Transformate, Sana',
    subtitle: 'Inti Kamari',
    detailCategoy: [],
};


export const ServiceExtraTabDataSource = {
    title: 'Servicios Adicionales',
    subtitle: 'Inti Kamari',
    image1: require('assets/images/intiServicios/comida1.jpg'),
    image2: require('assets/images/intiServicios/pet-friendly.jpg'),
    detailCategoy: [
        {
            eventKey: 'first',
            category: 'Pet Friendly',
            body: {
                image: require('assets/images/intiResource/5.png'),
                description:
                    <>
                        En Inti Kamari, amamos y respetamos a toda criatura que nos visita.
                        Ante esto, si vienes con tu peludito recuerda que no sea dominante, sea educado, no duerma en las camas, limpies sus desechos, no salten a la piscina y traigan su respectiva correa o arnés.<br /><br />
                        Todo esto porque compartimos el espacio con varias especies, entre ellas están: gansos, ardillas, muchos pajaritos y gatos.
                    </>,
            }
        },
        {
            eventKey: 'second',
            category: 'Alimentación Consciente',
            body: {
                image: require('assets/images/intiResource/5.png'),
                description:
                    <>
                        <>
                            Contamos con un restaurante con comida deliciosa, hecha con recetas tradicionales, a base de ingredientes extraídos de nuestra huerta orgánico.<br />
                            Esto nos permite mantener nuestra filosofía de alimentación consciente que se basa en mejorar la forma en la que comemos y cómo puede beneficiar esto a la salud de cada huésped.<br /><br />
                            ¡Te invitamos a disfrutarla!
                        </>
                    </>,
            }
        },
    ],
};
