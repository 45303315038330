import React from 'react';


export const ResponsabilidadDs = {
    title: 'Responsabilidades',
    subtitle: 'Voluntariado',
    body: "Como hemos sido viajeros, sabemos de esa búsqueda de un lugar para quedarse. Y siempre requerimos manos extra para todas las tareas del centro, como por ejemplo:",
    list: [
        {
            label: 'Limpieza y aseo general'
        },
        {
            label: 'Naturaleza: huerta, jardines, cosecha, etc',
        },
        {
            label: 'Fundacion – trabajo con los niños',
        },
        {
            label: 'Cocina para retiros',
        },
        {
            label: 'Reception y hosting para retiros',
        },
        {
            label: 'Produccion',
        },
        {
            label: 'Arte',
        },
    ],
};



export const TipoVoluntarioDs2 = {
    title: 'Nuestro centro siempre esta abierto a voluntarios',
    subtitle: 'Voluntariado',
    md: " col-md-12 col-lg-12",
    body: (
        <>
            Nuestro voluntariado no tiene costo. El trabajo es de 3 a 4 horas diarias por 5 días, con 2 días libres a la semana.
            Recibirás hospedaje completamente gratuito, a excepción de tu alimentación.
            Estamos en la búsqueda constante de personas que quieran encargarse de nuestra huerta orgánica, brindar sesiones de Yoga o realizar proyectos de arte.<br /><br />
            Es indispensable presentar tu propuesta personal a desarrollar dentro de nuestro hogar.

        </>
    ),
};


export const TipoVoluntarioDs = {
    title: 'Nuestro centro siempre esta abierto a voluntarios',
    subtitle: 'Voluntariado',
    image1: require('assets/images/intiGallery/actividades/6.jpg'),
    detailCategoy: [
        {
            eventKey: 'first',
            category: '',
            body: {
                image: require('assets/images/intiResource/5.png'),
                description:
                    <>
                        Nuestro voluntariado no tiene costo. El trabajo es de 3 a 4 horas diarias por 5 días, con 2 días libres a la semana.
                        Recibirás hospedaje completamente gratuito, a excepción de tu alimentación.
                        Estamos en la búsqueda constante de personas que quieran encargarse de nuestra huerta orgánica, brindar sesiones de Yoga o realizar proyectos de arte.<br /><br />
                        Es indispensable presentar tu propuesta personal a desarrollar dentro de nuestro hogar.

                    </>,
            }
        },

    ],
};


export const HabilidadesDs = {
    title: 'Habilidades Prioritarias',
    subtitle: 'Voluntarios',
    md: " col-md-3 col-lg-3",
    nocenter: true,
    detailCategoy: [
        {
            eventKey: 'second',
            img: require('assets/images/intiVoluntarios/h1.png'),
            title: "Cocina",
            icon: 'flaticon-notebook',
        },
        {
            eventKey: 'second',
            img: require('assets/images/intiVoluntarios/h2.png'),
            title: "Fotografos",
            icon: 'flaticon-notebook',
        },
        {
            eventKey: 'second',
            img: require('assets/images/intiVoluntarios/h3.png'),
            title: "Artes",
            icon: 'flaticon-notebook',
        },
        {
            eventKey: 'second',
            img: require('assets/images/intiVoluntarios/h4.png'),
            title: "Hosting",
            icon: 'flaticon-notebook',
        },
        {
            eventKey: 'second',
            img: require('assets/images/intiVoluntarios/h5.png'),
            title: "Instructores de yoga",
            icon: 'flaticon-notebook',
        },
        {
            eventKey: 'second',
            img: require('assets/images/intiVoluntarios/h6.png'),
            title: "Permacultura, agricultura, jardineria",
            icon: 'flaticon-notebook',
        },
        {
            eventKey: 'second',
            img: require('assets/images/intiVoluntarios/h7.png'),
            title: "Diseñador Web, redes sociales",
            icon: 'flaticon-notebook',
        },
        {
            eventKey: 'second',
            img: require('assets/images/intiVoluntarios/h8.png'),
            title: "Recreadores, profed. fisica, pedagogos",
            icon: 'flaticon-notebook',
        },
    ],
};


export const GaleriaVoluntariosDS = {
    title: 'Galeria',
    subtitle: 'Voluntarios',
    images: [
        {
            name: 'Voluntarios',
            img: require('assets/images/intiVoluntarios/g1.jpg'),

        },
        {
            name: 'Voluntarios',
            img: require('assets/images/intiVoluntarios/g2.jpg'),

        },
        {
            name: 'Voluntarios',
            img: require('assets/images/intiVoluntarios/g3.jpg'),

        },
        {
            name: 'Voluntarios',
            img: require('assets/images/intiVoluntarios/g4.jpg'),

        },
        {
            name: 'Voluntarios',
            img: require('assets/images/intiVoluntarios/g5.jpg'),

        },
        {
            name: 'Voluntarios',
            img: require('assets/images/intiVoluntarios/g6.jpg'),

        },
        {
            name: 'Voluntarios',
            img: require('assets/images/intiVoluntarios/g7.jpg'),

        },
    ]

}