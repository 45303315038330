import React from 'react';
import fondoInicial from '../images/img/fondo.png';
import retiros from '../images/img/retiros.jpg';
import hospedaje from '../images/img/hospedaje-1.jpg';
import comunidad from '../images/img/comunidad.jpg';
import voluntarios from '../images/img/voluntarios.jpg';


export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page kzxj497g8hn-editor_css' },
  logo: {
    className: 'header0-logo kzxgomwe99i-editor_css',
    children:
      'https://intikamari.com/wp-content/uploads/2019/02/logo_small.png',
  },
  dataMenu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '/',
          name: "Inicio"
        },
      },
      {
        name: 'item~serv',
        className: 'header0-item',
        children: {
          href: '#',
          name: "¿Quienes Somos?",
          submenu: [
            {
              href: '/comunidad',
              name: "Comunidad"
            },
            {
              href: '/voluntarios',
              name: "Voluntarios"
            },
          ]
        },
      },
      {
        name: 'item~serv',
        className: 'header0-item',
        children: {
          href: '#',
          name: "Servicios",
          submenu: [
            {
              href: '/retiros',
              name: "Retiros"
            },
            {
              href: '/apartamentos',
              name: "Apartamentos"
            },
          ]
        },
      },
      {
        name: 'itemGaleria',
        className: 'header0-item',
        children: {
          href: '/galeria',
          name: "Galería"
        },
      },
      {
        name: 'item~kzxn246esnh',
        className: 'header0-item',
        children: {
          href: '/contacto',
          name: "Contáctanos",
          submenu: [
            {
              href: '#',
              name: "WhatsApp"
            },
            {
              href: '#',
              name: "Correo"
            },
            {
              href: '/contacto',
              name: "Contactos"
            },
          ]
        },
      },
      {
        name: 'item~languaje',
        className: 'header0-item',
        children: {
          href: '#',
          name: "Idioma",
          submenu: [
            {
              href: '#',
              name: "Ingles"
            },
            {
              href: '#',
              name: "Español"
            },
          ]
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};

export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0 kzxj4vkmkga-editor_css' },
        title: {
          className: 'banner2-title kzxj93jx2i6-editor_css',
          children: (
            <span>
              <br />
              <span>
                <span>I N T I&nbsp;&nbsp; K A M A R I
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'banner2-content',
          children: (
            <span>
              <span>
                <p>CRECE, TRANSFORMATE, SANA</p>
              </span>
            </span>
          ),
        },
        button: { className: 'banner2-button', children: 'Learn More' },
      },
    ],
  },
};

export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: 'Seeking Experience & Engineering Conference',
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '首届蚂蚁金服体验科技大会',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: '探索极致用户体验与最佳工程实践探索',
      },
      { name: 'button', className: 'banner3-button', children: '立即报名' },
      {
        name: 'time',
        className: 'banner3-time',
        children: '2018.01.06 / 中国·杭州',
      },
    ],
  },
};


export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>QUIÉNES SOMOS</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              Somos un espacio para crecer y transformarse. Recibimos grupos que
              buscan realizar sus eventos en un ambiente de paz y buena energía,
              y también ofrecemos hospedaje para personas y familias, en
              habitaciones rodeadas de naturaleza que permiten reconectarse con
              el propio ser y el de los demás.
            </p>
          </span>
        ),
        className: 'title-content kzxkuq8vgvt-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};

export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: {
    className: 'home-page content1 kzxl9c6bnj-editor_css',
    playScale: 0.3,
  },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: (retiros),
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <p>RETIROS Y EVENTOS</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          Damos apertura a todas las disciplinas. Hemos recibido con gran
          acogida eventos de desarrollo personal, meditación, yoga y ceremonias
          ancestrales. Así como capacitaciones privadas, psicología y
          neurociencias, bio-construcción, danzas sufi, permacultura y
          nutrición.{' '}
        </p>
      </span>
    ),
  },
};

export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: (hospedaje),
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <p>HOSPEDAJE</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <span>
          <p>
            Habitaciones cómodas y con variedad de estilos y y ambientes para
            elegir. Individuales, familiares y grupales. Con vista al hermoso
            valle de Yunguilla, o rodeadas de nuestros huertos orgánicos.
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: (comunidad),
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <p>VINCULACIÓN CON LA COMUNIDAD</p>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          Somos parte activa de la comunidad Quillosisa, en donde Inti Kamari
          está ubicada. Con el apoyo de voluntarios y fundaciones, ejecutamos
          proyectos gratuitos para niños y adultos: teatro, pintura,
          agricultura, yoga, además de brigadas médicas y encuentros de
          intercambio de semillas y saberes.
        </p>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: (voluntarios),
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <p>VOLUNTARIOS</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          También hemos sido viajeros, y sabemos de esa búsqueda por un lugar
          para descansar, meditar y aprender. En Inti Kamari siempre requerimos
          de manos extra para todas las tareas del centro, y abrimos nuestras
          puertas durante nuestra temporada de voluntariado cuatro meses al año.
          Anímate a escribirnos.
        </p>
      </span>
    ),
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0 kzxlpm2ffm-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>NATURALEZA Y SERVICIOS</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block~kzxl4e73vc',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>Biblioteca con obras espirituales y científicas.</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kzxl4yvflji',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      Amplia sala de yoga. Salón de masajes. Áreas de reunión,
                      tanto al aire libre como en interiores.
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kzxl516nckh',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    Bosque frutal. Tres hectáreas de naturaleza: invernadero,
                    huerto orgánico de vegetales y plantas medicinales.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kzxl533cp0t',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>Hermoso lago de fuente mineral</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kzxl5563vhp',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>Senderos y caminatas ecológicas, ecoturismo</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kzxl56e4ht',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>Servicio de comida vegetariana y tradicional.</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>8 casas con 20 habitaciones (50 huespedes aprox.)</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      Amplia sala de yoga. Salón de masajes. Áreas de reunión,
                      tanto al aire libre como en interiores.
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>Dos piscinas</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4 kzxjv7ljey7-editor_css' },
  OverPack: {
    playScale: 0.3,
    className: 'kzxjv00wtrd-editor_css',
    replay: false,
    always: true,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  video: {
    className: 'content4-video kzxjuv6wi1l-editor_css',
    children: {
      video: 'https://www.youtube.com/watch?v=uRSHZ8-wMTw',
      image:
        'https://intikamari.com/wp-content/uploads/2019/02/hero_image_2.jpg',
    },
  },
};
export const DetalleCountHomeDataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    haveTitle: false,
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text',
          children: (
            <span>
              <span>
                <p>Detalle</p>
              </span>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'personas' },
              toText: true,
              children: '6000',
            },
            children: {
              className: 'feature6-text kzxlhybpzvl-editor_css',
              children: (
                <span>
                  <p>
                    ¡Se hospedaron en nuestro hogar!
                  </p>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'retiros' },
              toText: true,
              children: '200',
            },
            children: {
              className: 'feature6-text kzxlla7vfl-editor_css',
              children: (
                <span>
                  <span>
                    <p>Efectuados en 6 años</p>
                  </span>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'proyectos' },
              toText: true,
              children: '60',
            },
            children: {
              className: 'feature6-text kzxllh6npa-editor_css',
              children: (
                <span>
                  <p>
                    Y más, realizados a favor de nuestra comunidad
                  </p>
                </span>
              ),
            },
          },
        ],
      },
    ],
  },
};
export const Banner21DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner2-title kzxm17nre5q-editor_css',
          children: (
            <span>
              <span>
                <p>NUESTRA HISTORIA</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'banner2-content',
          children: (
            <span>
              <span>
                <p>
                  Más de dos mil personas nos han visitado en los últimos tres
                  años, en unos ciento veinte encuentros y retiros. Nuestra
                  fundación ha tenido ofrecido un centenar de talleres,
                  excursiones y brigadas médicas gratuitas a la comunidad.s
                  patrimonio cultural de la humanidad.
                </p>
              </span>
            </span>
          ),
        },
        button: { className: 'banner2-button', children: 'Learn More' },
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: {
    playScale: 0.3,
    className: 'home-page pricing0 kzxm3n5j6-editor_css',
  },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children:
      'https://images.squarespace-cdn.com/content/v1/5572b7b4e4b0a20071d407d4/1487088902799-1GTJMHIDWD221QONMTKS/Location+Based+Mobile-+Advertising',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper kzxm30db1z-editor_css',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>UBICACIÓN</p>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              Nos encontramos en el hermoso valle sub tropical de Yunguilla, en
              la cordillera de los Andes. Un gran paisaje montañoso y senderos
              naturales para caminar. Un clima agradable el año entero. Y a sólo
              una hora de Cuenca, la tercera ciudad del Ecuador, y cuyo centro
              es patrimonio cultural de la humanidad.
            </p>
          </span>
        ),
        className: 'pricing0-content',
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>GALERÍA</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
          },
          content: { children: 'Ant Design' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: { children: 'Ant Motion' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
          },
          content: { children: 'Ant Design' },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: { children: 'Ant Motion' },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
          },
          content: { children: 'Ant Design' },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: { children: 'Ant Motion' },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
          },
          content: { children: 'Ant Design' },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: { children: 'Ant Motion' },
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page kzxm7kirxut-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo kzxm7h6ttt-editor_css',
          children:
            'https://intikamari.com/wp-content/uploads/2019/02/Logo_white.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>Inti Kamari</p>
                  </span>
                </span>
              ),
              className: 'kzxm6sw7muo-editor_css',
            },
            {
              name: 'content~kzxmts7uyz',
              className: 'kzxmuj8fnsu-editor_css',
              children: (
                <span>
                  <p>+593 990221462</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>Servicios</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '#',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Retiros</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'link2',
              href: '#',
              children: (
                <span>
                  <p>Comunidad</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '#',
              children: (
                <span>
                  <p>Voluntarios</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Instalaciones</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>Apartamentos</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              children: (
                <span>
                  <p>Galeria</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>Contacto</p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Ubicación</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              children: (
                <span>
                  <p>Contacto</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kzxmedtm56o',
        xs: 24,
        md: 24,
        className: 'block kzxmegro3z-editor_css',
        title: {
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>BOOK NOW:</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
          className: 'kzxmfgcy89f-editor_css',
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>
                    Or make reservations by contacting us directly and save
                    money +593 990221462
                  </p>
                  <p>
                    *We enforce strict bio-security protocols and operate under
                    a reduced capacity for your safety and peace of mind*
                  </p>
                </span>
              ),
              className: 'kzxmesmy71-editor_css',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>
            <span>
              <a href="https://motion.ant.design">H</a>aku Innovaciones&nbsp;
            </span>
          </span>
        </span>© 2022&nbsp;
      </span>
    ),
  },
};

export const Feature50Retiros = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '蚂蚁金融云提供专业的服务',
        className: 'title-h1',
      },
      { name: 'content', children: '基于阿里云计算强大的基础资源' },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { children: 'Objetivos', className: 'content7-tag-name' },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <p>
                  Promovemos la realización de retiros de distintas corrientes. Extraemos nuestros conocimientos desde las rigurosas ciencias, hasta las antiguas tradiciones y religiones de todo el mundo.                </p>
                <br />
                <p>
                  Damos apertura a disciplinas que van desde la psicología y las neurociencias hasta la meditación y el yoga. La permacultura, la nutrición, la medicina ancestral y el arte, se encuentran entre nuestro arsenal de herramientas.                </p>
                <br />
                <p>
                  Un retiro podría ser el último empujón que necesitas, traer revelaciones inesperadas o darte la relajación más valiosa que hayas tenido en años.
                </p>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'tablet' },
          text: { className: 'content7-tag-name', children: 'Siguientes Retiros' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>PARA ORGANIZADORES</h3>
                <p>Podemos darte tanta libertad como desees o tanta ayuda como requieras con la organización de tu retiro.
                </p>
                <br />
                <p>
                  Durante los últimos años, Cuenca silenciosamente se ha desarrollado para convertirse en una de las ciudades de Sudamérica más activas espiritualmente. Abrimos la posibilidad a organizadores y facilitadores provenientes de otros centros, para compartir propuestas y proyectos de crecimiento personal y espiritual. Podemos darte tanta libertad como desees o tanta ayuda como requieras con la organización de tu retiro.
                </p>
                <br />
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
    ],
  },
};

export const Banner30Galeria = {
  wrapper: { className: 'bannerGaleria' },
  textWrapper: {
    className: 'bannerGaleria-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'bannerGaleria-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'bannerGaleria-slogan',
        children: 'GALERIA',
        texty: true,
      },
      {
        name: 'name',
        className: 'bannerGaleria-name',
        children: 'CRECE, TRANSFÓRMATE, SANA',
      },
    ],
  },
};

export const GaleriaDataSource = {
  wrapper: { className: 'home-page-wrapper content8-wrapper' },
  page: { className: 'home-page content8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    subtitulo: 'A LA EXPERIENCIA EN NUESTRO CENTRO    ',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      { name: 'title', children: 'ECHA UN VISTAZO', className: 'title-h1' },
    ],
  },
  block: {
    className: 'content-wrapper',
    children: [
      {
        name: 'block0',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2018/12/1.7.jpeg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block1',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2018/12/peace-revolution-girl-listening.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block2',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2018/12/2.2.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block3',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2019/02/yogafam124.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block12',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2019/02/yogafam124.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block13',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2019/02/yogafam124.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block23',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2019/02/yogafam124.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block36',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2019/02/yogafam124.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block12',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2019/02/yogafam124.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block13',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2019/02/yogafam124.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block23',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2019/02/yogafam124.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block36',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://intikamari.com/wp-content/uploads/2019/02/yogafam124.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
    ],
  },
};


export const Banner30Retiros = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: 'RETIROS',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: 'CRECE, TRANSFÓRMATE, SANA',
      },
      // { name: 'button', className: 'banner3-button', children: 'Contacto' },
    ],
  },
};

export const RetiroDataSource = {
  wrapper: { className: 'home-page-wrapper contentHeader-wrapper' },
  page: { className: 'home-page contentHeader' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    subtitulo: 'Más de 80 retiros efectuados en 2 años',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      { name: 'title', children: 'ANIMATE!', className: 'title-h1' },
    ],
  },
  blockWrapper: {
    wrapper: { className: 'home-page-wrapper contentInfo-wrapper' },
    OverPack: {
      className: 'home-page contentInfo kzxl9c6bnj-editor_css',
      playScale: 0.3,
    },
    imgWrapper: { className: 'contentInfo-img', md: 10, xs: 24 },
    img: {
      children: (retiros),
    },
    textWrapper: { className: 'contentInfo-text', md: 14, xs: 24 },
    title: {
      className: 'contentInfo-title',
      children: (
        <span>
          <span>
            <p>OBJETIVOS</p>
          </span>
        </span>
      ),
    },
    content: {
      className: 'contentInfo-content',
      children: (
        <span>
          <p>
            Promovemos la realización de retiros de distintas corrientes. Extraemos nuestros conocimientos desde las rigurosas ciencias, hasta las antiguas tradiciones y religiones de todo el mundo.
          </p>
          <p>
            Damos apertura a disciplinas que van desde la psicología y las neurociencias hasta la meditación y el yoga. La permacultura, la nutrición, la medicina ancestral y el arte, se encuentran entre nuestro arsenal de herramientas.
          </p>
          <p>
            Un retiro podría ser el último empujón que necesitas, traer revelaciones inesperadas o darte la relajación más valiosa que hayas tenido en años.
          </p>
        </span>
      ),
    },
  }
};


export const Retiro2DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: (hospedaje),
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <p>PARA ORGANIZADORES</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <span>
          <p>
            Podemos darte tanta libertad como desees o tanta ayuda como requieras con la organización de tu retiro.
          </p>
          <p>
            Durante los últimos años, Cuenca silenciosamente se ha desarrollado para convertirse en una de las ciudades de Sudamérica más activas espiritualmente. Abrimos la posibilidad a organizadores y facilitadores provenientes de otros centros, para compartir propuestas y proyectos de crecimiento personal y espiritual. Podemos darte tanta libertad como desees o tanta ayuda como requieras con la organización de tu retiro.
          </p>
        </span>
      </span>
    ),
  },
};


export const RetiroOrganizacionDataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: {
    className: 'home-page content1 kzxl9c6bnj-editor_css',
    playScale: 0.3,
  },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: (retiros),
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <p>PARA ORGANIZADORES</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>Podemos darte tanta libertad como desees o tanta ayuda como requieras con la organización de tu retiro.
          Durante los últimos años, Cuenca silenciosamente se ha desarrollado para convertirse en una de las ciudades de Sudamérica más activas espiritualmente. Abrimos la posibilidad a organizadores y facilitadores provenientes de otros centros, para compartir propuestas y proyectos de crecimiento personal y espiritual. Podemos darte tanta libertad como desees o tanta ayuda como requieras con la organización de tu retiro.
        </p>
      </span>
    ),
  },
};



export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: 'Actividades',
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: ' ',
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              image: true,
              className: 'feature7-block-image',
              children: (hospedaje),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'Meditacion y Yoga',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              image: true,
              className: 'feature7-block-image',
              children: (comunidad),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'Neurociencia y Psicologia',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              image: true,
              className: 'feature7-block-image',
              children: (voluntarios),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'Retiros de parejas, espirituales, del silencio',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              image: true,
              className: 'feature7-block-image',
              children: (retiros),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'Arte y ciencia, seminarios, trabajo en equipo',
            },
          ],
        },
      },
    ],
  },
};




export const BannerApartamento = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: 'APARTAMENTOS',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: 'CRECE, TRANSFÓRMATE, SANA',
      },
      // { name: 'button', className: 'banner3-button', children: 'Contacto' },
    ],
  },
};

export const HeaderApartamentoDataSource = {
  wrapper: { className: 'home-page-wrapper contentHeader-wrapper' },
  page: { className: 'home-page contentHeader' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    subtitulo: '',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      { name: 'title', children: 'HERMOSO Y PACÍFICO LUGAR PARA TODOS', className: 'title-h1' },
    ],
  },
  blockWrapper: {
    wrapper: { className: 'home-page-wrapper contentInfo-wrapper' },
    OverPack: {
      className: 'home-page contentInfo kzxl9c6bnj-editor_css',
      playScale: 0.3,
    },
    imgWrapper: { className: 'contentInfo-img', md: 10, xs: 24 },
    img: {
      children: (retiros),
    },
    textWrapper: { className: 'contentInfo-text', md: 14, xs: 24 },
    title: {
      className: 'contentInfo-title',
      children: (
        <span>
        </span>
      ),
    },
    content: {
      className: 'contentInfo-content',
      children: (
        <span>
          <p> El INTI KAMARI ofrece alojamiento con conexión WiFi gratuita, piscina, jardín, salón compartido y vistas al lago. Hay una zona de estar y comedor en todas las unidades. Los huéspedes del Homestay pueden disfrutar de un desayuno bufé. INTI KAMARI ofrece una bañera de hidromasaje. Los huéspedes también pueden relajarse en la terraza.          </p>
        </span>
      ),
    },
  }
};



export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'NATURALEZA Y SERVICIOS',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: 'Contamos con hermosos lugares recreativos',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Piscinas' },
          content: {
            className: 'content3-content',
            children:
              'Dos piscinas',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Biblioteca' },
          content: {
            className: 'content3-content',
            children:
              'Biblioteca con obras espirituales y científicas.',
          },
        },
      },
      {
        name: 'block6',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Lago' },
          content: {
            className: 'content3-content',
            children:
              'Hermoso lago de fuente mineral',
          },
        },
      },
      {
        name: 'block7',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Caminatas' },
          content: {
            className: 'content3-content',
            children:
              'Senderos y caminatas ecológicas, ecoturismo',
          },
        },
      },
      {
        name: 'block9',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Alimentación' },
          content: {
            className: 'content3-content',
            children:
              'Servicio de comida vegetariana y tradicional.',
          },
        },
      },
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Casas' },
          content: {
            className: 'content3-content',
            children:
              '8 casas con 20 habitaciones (50 huespedes aprox.)',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Salas' },
          content: {
            className: 'content3-content',
            children:
              'Amplia sala de yoga. Salón de masajes. Áreas de reunión, tanto al aire libre como en interiores.',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Áreas Verdes' },
          content: {
            className: 'content3-content',
            children:
              'Bosque frutal. Tres hectáreas de naturaleza: invernadero, huerto orgánico de vegetales y plantas medicinales. ',
          },
        },
      },
    ],
  },
};



export const Feature81DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1',
        children: '¿QUÉ OFRECEMOS?'
      },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: 'Servicios e instalaciones',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: { href: '#', children: 'Conocer más..' },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: 'INSTALACIONES',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: 'RETIROS Y EVENTOS',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
          ],
        },
        {
          name: 'block1',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: '平台自主训练流程',
            className: 'feature8-carousel-title-block',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};



export const OfertasDataSource = {
  wrapper: { className: 'home-page-wrapper contentServicio-wrapper' },
  page: { className: 'home-page contentServicio' },
  OverPack: {
    playScale: 0.3,
  },
  block: {
    className: 'contentServicio-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'contentServicio-block',
        md: 6,
        xs: 24,
        imgWrapper: { className: 'contentServicio-img', md: 8, xs: 24 },
        img: { children: (retiros) },
        textWrapper: { className: 'contentServicio-text', md: 8, xs: 24 },
        title: {
          className: 'contentServicio-title',
          children: (
            <span>
              <span>
                <p>RETIROS Y EVENTOS</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'contentServicio-content',
          children: (
            <span>
              <p>
                Damos apertura a todas las disciplinas. Hemos recibido con gran
                acogida eventos de desarrollo personal, meditación, yoga y ceremonias
                ancestrales. Así como capacitaciones privadas, psicología y
                neurociencias, bio-construcción, danzas sufi, permacultura y
                nutrición.{' '}
              </p>
            </span>
          ),
        },
        button: {
          name: 'button',
          className: 'contentServicio-button',
          children: { href: '/retiros', name: 'Conocer más..' },
        }
      },
      {
        name: 'block0',
        className: 'contentServicio-block',
        md: 6,
        xs: 24,
        imgWrapper: { className: 'contentServicio-img', md: 8, xs: 24 },
        img: {
          children: (retiros),
        },
        textWrapper: { className: 'contentServicio-text', md: 8, xs: 24 },
        title: {
          className: 'contentServicio-title',
          children: (
            <span>
              <span>
                <p>HOSPEDAJE</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'contentServicio-content',
          children: (
            <span>
              <p>Habitaciones cómodas y con variedad de estilos y y ambientes para elegir. Individuales, familiares y grupales. Con vista al hermoso valle de Yunguilla, o rodeadas de nuestros huertos orgánicos.
              </p>
            </span>
          ),
        },
        button: {
          name: 'button',
          className: 'contentServicio-button',
          children: { href: '/apartamentos', name: 'Conocer más..' },
        }
      },
      {
        name: 'block0',
        className: 'contentServicio-block',
        md: 6,
        xs: 24,
        imgWrapper: { className: 'contentServicio-img', md: 8, xs: 24 },
        img: {
          children: (retiros),
        },
        textWrapper: { className: 'contentServicio-text', md: 8, xs: 24 },
        title: {
          className: 'contentServicio-title',
          children: (
            <span>
              <span>
                <p>COMUNIDAD</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'contentServicio-content',
          children: (
            <span>
              <p>Somos parte activa de la comunidad Quillosisa, en donde Inti Kamari está ubicada. Con el apoyo de voluntarios y fundaciones, ejecutamos proyectos gratuitos para niños y adultos: teatro, pintura, agricultura, yoga, además de brigadas médicas y encuentros de intercambio de semillas y saberes. </p>
            </span>
          ),
        },
        button: {
          name: 'button',
          className: 'contentServicio-button',
          children: { href: '/comunidad', name: 'Conocer más..' },
        }
      },
      {
        name: 'block0',
        className: 'contentServicio-block',
        md: 6,
        xs: 24,
        imgWrapper: { className: 'contentServicio-img', md: 8, xs: 24 },
        img: {
          children: (retiros),
        },
        textWrapper: { className: 'contentServicio-text', md: 8, xs: 24 },
        title: {
          className: 'contentServicio-title',
          children: (
            <span>
              <span>
                <p>VOLUNTARIOS</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'contentServicio-content',
          children: (
            <span>
              <p>También hemos sido viajeros, y sabemos de esa búsqueda por un lugar para descansar, meditar y aprender. En Inti Kamari siempre requerimos de manos extra para todas las tareas del centro, y abrimos nuestras puertas durante nuestra temporada de voluntariado cuatro meses al año. Anímate a escribirnos.</p>
            </span>
          ),
        },
        button: {
          name: 'button',
          className: 'contentServicio-button',
          children: { href: '/voluntarios', name: 'Conocer más..' },
        }
      }

    ]
  }
};

export const TituloUbicacionDataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: {
    playScale: 0.3,
    className: 'home-page pricing0 kzxm3n5j6-editor_css',
  },
  children: [
    {
      childWrapper: {
        className: 'pricing0-text-wrapper kzxm30db1z-editor_css',
        md: 12,
        xs: 24,
        children: [
          {
            name: 'title',
            children: (
              <span>
                <p> NUESTRA HISTORIA</p>
              </span>
            ),
            className: 'pricing0-title',
          },
          {
            name: 'content',
            children: (
              <span>
                <p>
                  Más de dos mil personas nos han visitado en los últimos tres años, en unos ciento veinte encuentros y retiros. Nuestra fundación ha tenido ofrecido un centenar de talleres, excursiones y brigadas médicas gratuitas a la comunidad.
                </p>
              </span>
            ),
            className: 'pricing0-content',
          },
        ],
      },
    },
    {
      childWrapper: {
        className: 'pricing0-text-wrapper kzxm30db1z-editor_css',
        md: 12,
        xs: 24,
        children: [
          {
            name: 'title',
            children: (
              <span>
                <p>UBICACIÓN</p>
              </span>
            ),
            className: 'pricing0-title',
          },
          {
            name: 'content',
            children: (
              <span>
                <p>
                  Nos encontramos en el hermoso valle sub tropical de Yunguilla, en
                  la cordillera de los Andes. Un gran paisaje montañoso y senderos
                  naturales para caminar. Un clima agradable el año entero. Y a sólo
                  una hora de Cuenca, la tercera ciudad del Ecuador, y cuyo centro
                  es patrimonio cultural de la humanidad.
                </p>
              </span>
            ),
            className: 'pricing0-content',
          },
        ],
      },
    }

  ]
};


export const Contact00DataSource = {
  wrapper: { className: 'home-page-wrapper content10-wrapper' },
  page: { className: 'home-page contentServicio' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Ubicacion</p>
          </span>
        ),
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              Nos encontramos en el hermoso valle sub tropical de Yunguilla, en
              la cordillera de los Andes. Un gran paisaje montañoso y senderos
              naturales para caminar. Un clima agradable el año entero. Y a sólo
              una hora de Cuenca, la tercera ciudad del Ecuador, y cuyo centro
              es patrimonio cultural de la humanidad.
            </p>
          </span>
        ),
      }
    ],
  },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: {
        children: 'https://www.google.com/maps/@-3.2675941,-79.293553,3a,82.2y,356.58h,82.96t/data=!3m7!1e1!3m5!1sAF1QipPQbbMdgr6X5uUyAJjW1Bpen7ES5FmtM2H5C2rY!2e10!3e12!7i6912!8i3456',
        name: '跳转地址'
      },
      title: { children: '大会地址', name: '弹框标题' },
      content: {
        children: '蚂蚁 Z 空间  浙江省杭州市西湖区西溪路556号',
        name: '弹框内容',
      },
    },
  },
};