import { ComunidadDS } from 'assets/data/data.source.comunidad';
import CustomPage from 'components/page/CustomPage';
import SectionTabs2 from 'components/section/SectionTabs2';
import React, { Component } from 'react';
import { rutas } from 'router/rutas';

const aboutbg = require('assets/images/intiBackground/bg8.jpg');

class PortfolioDetails extends Component {


    render() {
        return (
            <CustomPage header
                image={aboutbg}
                title={rutas.comunidad.label}
                subtitle={rutas.comunidad.subtitle}>
                <br />
                <br />
                <SectionTabs2
                    dataSource={ComunidadDS} />
                <br />
                <br />
                <br />
            </CustomPage>
        )
    }
}
export default PortfolioDetails;