import ReservarLink from 'components/link/ReservarLink';
import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

class SectionGaleria extends Component {
    render() {
        const { ...props } = this.props;
        return (
            <>
                <section class="cases-section">

                    {/* <!--case Tabs--> */}
                    <div class="case-tabs">
                        <div class="auto-container">


                            {this.props.dataSource.title && (
                                <div class={this.props.dataSource.nocenter ?
                                    "sec-title   "
                                    : "sec-title   text-center"}>
                                    <div class="sub-title">{this.props.dataSource.subtitle}</div>

                                    <h2>{this.props.dataSource.title}</h2>
                                </div>
                            )}


                            <div class="case-tab-wrapper">
                                {/* <!--Tabs Content--> */}
                                <div class="case-tabs-content">
                                    {/* <!--case Tab / Active Tab--> */}
                                    <div class="case-tab active-tab" id="case-tab-1">
                                        <div class="theme_carousel owl-theme owl-carousel" data-options='{"loop": true, "margin": 0, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 300, "responsive":{ "0" :{ "items": "1" }, "768" :{ "items" : "1" } , "1000":{ "items" : "1" }}}'>


                                            <Slider
                                                dots={false}
                                                slidesToShow={2}
                                                slidesToScroll={2}
                                                autoplay={true}
                                                autoplaySpeed={3000}  >

                                                {this.props.dataSource.images.map((item, id) => (
                                                    <div class="case-block-one">
                                                        <div class="inner-box">
                                                            <div class="image"><img src={item.img} alt="" /></div>
                                                            <div class="overlay">
                                                                <div>
                                                                    <h5>{item.name}</h5>
                                                                    <div class="link-btn flex-box">
                                                                        <ReservarLink iconBtn={"flaticon-call-1"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </>
        )
    }
}
export default SectionGaleria; 