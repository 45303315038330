import { ActividadesDataSource, RetiroTabDataSource } from 'assets/data/data.source.retiro';
import CustomPage from 'components/page/CustomPage';
import SectionOptions from 'components/section/SectionOptions';
import SectionTabs2 from 'components/section/SectionTabs2';
import React, { Component } from 'react';
import { rutas } from 'router/rutas';

const aboutbg = require('assets/images/intiBackground/bg3.jpg');

/**
 * 
 */
class Retiros extends Component {

    render() {
        return (
            <CustomPage header
                image={aboutbg}
                title={rutas.retiros.label}
                subtitle={rutas.retiros.subtitle}>
                <br />
                <br />
                <SectionOptions
                    dataSource={ActividadesDataSource} />
                <br />
                <SectionTabs2
                    dataSource={RetiroTabDataSource} />
                <br /><br /><br />
            </CustomPage>
        )
    }
}
export default Retiros;