import React from 'react';
import { ContactoDs } from './data.source.contacto.js';

export const PrecioHabitacionDS = {
    title: 'Precios',
    subtitle: 'Habitaciones',
    md: "col-md-3 col-lg-3",
    body:
        <>
            Todos los valores incluyen impuestos, uso de todas las instalaciones y clases de yoga por la mañana.<br />
            Recuerda:<br />
            Estos precios son promocionales con pago en efectivo, depósito o transferencia e incluyen impuestos.<br />
            
        </>,
    detailCategoy: [
        {
            eventKey: 'first',
            title: "HABITACION DOBLE O MATRIMONIAL",
            category: <>Valor por adulto:<br />
                $ 49,50<br />
                Valor por niño de 5 -12 años:<br />
                $ 33,50<br />
                * Incluye desayuno
            </>,
            icon: 'flaticon-money-1',
            img: require('assets/images/intiHabitacion/1.jpg'),
        },
        {
            eventKey: 'second',
            title: "HABITACION INDIVIDUAL",
            category: <>Valor por adulto:<br />
                $ 63,50<br />
                * Incluye desayuno
            </>,
            icon: 'flaticon-money-1',
            img: require('assets/images/intiServicios/instalaciones.png'),
        },
        {
            eventKey: 'third',
            title: "TERCER ADULTO EN HABITACIÓN",
            category: <>Valor por adulto:<br />
                $ 39,50<br />
                * Incluye desayuno
            </>,
            icon: 'flaticon-money-1',
            img: require('assets/images/intiHabitacion/8.jpg'),
        },
        {
            eventKey: 'four',
            title: "ALIMENTACION",
            category: <>Almuerzo o Cena por adulto:<br />
                $ 9,50<br />
                Almuerzo o Cena por niño hasta 12 años:<br />
                $ 7,50
            </>,
            icon: 'flaticon-money-1',
            img: require('assets/images/intiHabitacion/8.jpg'),
        },
    ],
};

export const ActividadesDataSource = {
    title: (<> Hermoso y pacífico <br /> lugar para todos</>),
    subtitle: 'Inti Kamari',
    detailCategoy: [
        {
            eventKey: 'second',
            img: require('assets/images/icons/inti-icon-5.png'),
            title: "Idiomas",
            category: (
                <>
                    Ingles <br />
                    Español
                </>
            ),
            icon: 'flaticon-notebook',
        },
        {
            eventKey: 'fourth',
            img: require('assets/images/icons/inti-icon-4.png'),
            title: "Recreación",
            category: (
                <>
                    Piscina al aire libre <br />
                    Biblioteca
                </>
            ),
            icon: 'flaticon-world',
        },
        {
            eventKey: 'third',
            img: require('assets/images/icons/inti-icon-1.png'),
            title: "Habitaciones",
            category: (
                <>
                    Wi-Fi en algunas habitaciones <br />
                    Habitaciones familiares <br />
                    Terraza, Jardin
                </>
            ),
            icon: 'flaticon-money-1',
        },
        {
            eventKey: 'first',
            img: require('assets/images/icons/inti-icon-3.png'),
            title: "Servicios Adicionales",
            category: (
                <>
                    Parking gratis privado <br />
                    Cocina compartida <br />
                    Bañera de hidromasaje / jacuzzi<br />
                    Zona TV / salón de uso compartido
                </>
            ),
            icon: 'flaticon-team',
        },
    ],
};

export const GaleriaHabitacionDS = {
    title: 'Galeria',
    subtitle: 'Habitaciones',
    images: [
        {
            name: 'Habitacion triple',
            img: require('assets/images/intiHabitacion/1.jpg'),

        },
        {
            name: 'Habitacion triple',
            img: require('assets/images/intiHabitacion/2.jpg'),

        },
        {
            name: 'Habitacion triple',
            img: require('assets/images/intiHabitacion/3.jpg'),

        },
        {
            name: 'Habitacion triple',
            img: require('assets/images/intiHabitacion/4.jpg'),

        },
        {
            name: 'Habitacion doble',
            img: require('assets/images/intiHabitacion/5.jpg'),

        },
        {
            name: 'Habitacion doble',
            img: require('assets/images/intiHabitacion/6.jpg'),

        },
        {
            name: 'Habitacion doble',
            img: require('assets/images/intiHabitacion/7.jpg'),

        },
        {
            name: 'Habitacion doble',
            img: require('assets/images/intiHabitacion/8.jpg'),

        },
    ]

}

export const TipoHabitacionDS = {
    title: 'Habitaciones',
    subtitle: 'Servicios',
    md: "col-md-4 col-lg-4",
    body:
        <>   ¡Aprovecha nuestros espacios disponibles y planifica tu estancia desde hoy!
        </>,
    detailCategoy: [
        {
            eventKey: 'third',
            title: "Habitacion Doble",
            category: <>
                Habitación doble con baño privado, zona de estar,
                zona de comedor y vistas al jardín.
            </>,
            icon: 'flaticon-money-1',
            img: require('assets/images/intiHabitacion/8.jpg'),
        },
        {
            eventKey: 'first',
            title: "Habitacion Triple",
            category: <>Habitación triple con baño privado, zona de estar,
                zona de comedor y vistas al jardín.


            </>,
            icon: 'flaticon-team',
            img: require('assets/images/intiHabitacion/1.jpg'),
        },
        {
            eventKey: 'second',
            title: "Acceso a Instalaciones",
            category: <>Áreas verdes, restaurant, salas, etc</>,
            icon: 'flaticon-world',
            img: require('assets/images/intiServicios/instalaciones.png'),
        },
    ],
};

export const HospedajeDataSource = {
    title: 'Hospedaje',
    subtitle: (<> Hermoso y pacífico <br /> lugar para todos</>),
    body: (<>
        <>
            Nuestro compromiso está con la comunidad de Quillosisa y zonas aledañas de Santa Isabel, Azuay.<br />
            Participamos en la creación del Parque Público Agroecológico, murales y varios eventos en los que participan <br /> 
            los niños de nuestra fundación.
            Además, recibimos voluntarios nacionales y extranjeros que deseen ayudar y<br />
             comprometerse con las tareas diarias que existen dentro de Inti Kamari.
        </><br />
    </>),
    contacto: {
        title: 'Contáctanos',
        body: [
            {
                label: ContactoDs.subtituloLocation,
                value: ContactoDs.location.label,
            },
            {
                label: ContactoDs.subtituloEmail,
                value: ContactoDs.email.label,
            },
            {
                label: ContactoDs.subtituloPhone,
                value: ContactoDs.phone1.label,
            },
            {
                label: '',
                value: ContactoDs.phone2.label,
            },
        ]
    }
};
