import { openInNewTab } from 'components/util';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 *
 * @param {*} props
 * @returns
 */
const ReservarLink = props => {
    const { iconBtn, onlyLink, children } = props;
    return (
        <>
            {iconBtn && (
                <Link data-fancybox="gallery-1"
                    onClick={() => openInNewTab("https://www.booking.com/hotel/ec/inti-kamari-quillosisa-yunguilla-valley.es.html")}
                    data-caption=""
                    class="theme-btn">
                    <i class={iconBtn}></i>
                    {children}
                </Link>)}

            {!iconBtn && (
                <Link
                    onClick={() => openInNewTab("https://www.booking.com/hotel/ec/inti-kamari-quillosisa-yunguilla-valley.es.html")}
                    class="theme-btn btn-style-one ">
                    {onlyLink && (<> </>)}
                    {!onlyLink && (<span class="btn-title " style={{ background: "#c1940c" }}>{'Reservar'}</span>)}
                    {children}
                </Link>)}


        </>
    );
};

export default ReservarLink;
