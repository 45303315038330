import { Row, Spin } from 'antd';
import 'assets/css/slider.css';
import { Images } from 'assets/images/Images';
import Background from 'components/background/background';
import Section from 'components/background/section/section';
import React, { Component, Suspense } from 'react';
import 'react-animated-slider/build/horizontal.css';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const logo = require('assets/images/Logo_white.png');
const slogan = require('assets/images/inti-slogan.png');
const icono1 = require('assets/images/icons/inti-icon-1.png');
const icono2 = require('assets/images/icons/inti-icon-2.png');
const icono3 = require('assets/images/icons/inti-icon-3.png');
const icono4 = require('assets/images/icons/inti-icon-4.png');
const icono5 = require('assets/images/icons/inti-icon-5.png');

const im1 = React.lazy(() => import('assets/images/main-slider/portada-1.jpeg'));

const AutoplaySlider = withAutoplay(AwesomeSlider);

const slides = [
    {
        title: 'INTI KAMARI',
        subtitle: 'Wellness Lodge & Convention Center',
        description: 'Our company is one of the world’s leading management consulting firms. Get in touch here asap.',
        btnLearn: "Iniciar",
        finan: "Inti",
        dox: " Kamari",
        btnService: "Our Services",
        image: "background1",
    },
   /* {
        title: 'INTI KAMARI',
        subtitle: 'Wellness Lodge & Convention Center',
        description: 'Our company is one of the world’s leading management consulting firms. Get in touch here asap.',
        btnLearn: "Iniciar",
        finan: "Inti",
        dox: " Kamari",
        btnService: "Our Services",
        image: "background2",
    },
    {
        title: 'INTI KAMARI',
        subtitle: 'Wellness Lodge & Convention Center',
        description: 'Our company is one of the world’s leading management consulting firms. Get in touch here asap.',
        btnLearn: "Iniciar",
        finan: "Inti",
        dox: " Kamari",
        btnService: "Our Services",
        image: "background3",
    },
*/
];
class HomeSlider1 extends Component {

    render() {
        return (
            <>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={9000}>
                    {slides.map((slide, index) =>
                        <Section wrapper={false} >
                            <Background className={(slide.image)} />
                            <div key={index}
                                className="slider-content slider-content-s" >
                                <div className="inner">
                                    <h1>{slide.title}</h1>
                                    <img src={slogan} className='banner2-img-wrapper' alt="img" />
                                    <h3> {slide.subtitle}</h3>
                                    <Row justify='center' gutter={25}>
                                        <Suspense fallback={<Spin />}>
                                            <img className='header-icono' src={icono1} alt="img" />
                                            <img className='header-icono' src={icono2} alt="img" />
                                            <img className='header-icono' src={icono3} alt="img" />
                                            <img className='header-icono' src={icono4} alt="img" />
                                            <img className='header-icono' src={icono5} alt="img" />

                                        </Suspense>
                                    </Row>
                                </div>
                            </div>
                        </Section>
                    )}
                </AutoplaySlider>
            </>
        )
    }
}
export default HomeSlider1;

/**
 * 
                        <div key={index}
                            className="slider-content slider-content-s"
                            style={{
                                background: `url('${slide.image}')  no-repeat center center`
                            }}>
                            <div className="inner">
                                <img width={'20%'}
                                    src={'https://intikamari.com/wp-content/uploads/2019/02/Logo_white.png'}
                                    className='banner2-img-wrapper' alt="img" />

                                <h1>{slide.title}</h1>
                                <h3> {slide.subtitle}</h3>
                            </div>
                        </div>
 */