import React from 'react';
import { ContactoDs } from './data.source.contacto.js';

export const ComunidadDS = {
    title: 'Vinculación con la comunidad',
    subtitle: 'Inti Kamari',
    image1: require('assets/images/intiResource/9.png'),
    image2: require('assets/images/intiResource/9.png'),
    detailCategoy: [
        {
            eventKey: 'first',
            category: 'Detalle',
            body: {
                image: require('assets/images/intiResource/5.png'),
                description:
                    (<>
                        Nuestro compromiso está con la comunidad de Quillosisa y zonas aledañas de Santa Isabel, Azuay.<br />
                        Participamos en la creación del Parque Público Agroecológico, murales y varios eventos en los que participan los niños de nuestra fundación.<br />
                        Además, recibimos voluntarios nacionales y extranjeros que deseen ayudar y comprometerse con las tareas diarias que existen dentro de Inti Kamari. </>),
            }
        },
    ],
};
