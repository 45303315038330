import LinkComponent from 'components/link';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { menu } from 'router/rutas';

class Menu extends Component {
    render() {

        const getMenu = () =>
            menu.children.map((item) => {
                if (!item.children.submenu) {
                    return (
                        <li><LinkComponent to={item.children.href}>{item.children.name}</LinkComponent></li>
                    );
                } else {
                    return (
                        <li key={"subp-" + item.children.name} class="dropdown"><Link to={'#'}>{item.children.name}</Link>
                            <ul>
                                {item.children.submenu.map((submenu) => {
                                    return (
                                        <li>
                                            <LinkComponent to={submenu.href}>
                                                {submenu.name}
                                            </LinkComponent>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    );
                }
            });

        return (
            <>
                <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                    <ul class="navigation clearfix">
                        {getMenu()}
                    </ul>
                </div>

            </>
        )
    }
}
export default Menu;