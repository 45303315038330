import ReservarLink from "components/link/ReservarLink";
import { openInNewTab } from "components/util";
import React, { Component } from 'react';
import { Link } from "react-router-dom"; 


//https://themeforest.net/item/avson-hotel-room-booking-reactjs-template/27540184

export const linksSocial = (<>
    <li>
        <Link onClick={() => openInNewTab("https://www.facebook.com/intikamari")}>
            <span class="fab fa-facebook-f"></span>
        </Link>
    </li>
    <li>
        <Link onClick={() => openInNewTab("https://www.instagram.com/intikamari/")}>
            <span class="fab fa-instagram"></span>
        </Link>
    </li>
    <li>
        <Link onClick={() => openInNewTab("https://api.whatsapp.com/send?phone=593990221462")}>
            <span class="fab fa-whatsapp"></span>
        </Link>
    </li>
    <li> <a href={"tel:" + '+593 (0)99-022-1462'}>  <span class="flaticon-phone"></span> </a></li>
    <li>
        <Link onClick={() => openInNewTab("https://www.booking.com/hotel/ec/inti-kamari-quillosisa-yunguilla-valley.es.html")}>
            <span class="flaticon-place"></span>
        </Link>
    </li></>);

export const RedesSocialesLinkDs = (
    <ul class="social-links clearfix">
        {linksSocial}
    </ul>
);
export const OnlyIconsSocialDs = (
    <ul class="social-links clearfix navbar-expand-md">
        {linksSocial}
    </ul>
);


export const ContactoDs = {
    titulo1: 'CONTACTO',
    titulo2: 'Contáctanos',
    subtituloPhone: 'Teléfono',
    subtituloEmail: 'Correo',
    subtituloLocation: 'Ubicación',
    booking: {
        icono: "flaticon-place",
        link: (
            <Link onClick={() => openInNewTab("https://www.booking.com/hotel/ec/inti-kamari-quillosisa-yunguilla-valley.es.html")}>
                <img width={40} src={require('assets/images/icons/b.png')} alt="" />
            </Link>),
        label: '+593 (0)99-022-1462'
    },
    maps: {
        icono: "flaticon-place",
        link: (
            <Link onClick={() => openInNewTab("https://www.google.com/maps/place/Inti+Kamari/@-3.2688203,-79.2944397,15z/data=!4m5!3m4!1s0x0:0x123c61be4b27515a!8m2!3d-3.2688207!4d-79.2944344")}>
                <img width={40} src={require('assets/images/icons/google_maps.png')} alt="" />
            </Link>),
        label: '+593 (0)99-022-1462'
    },
    whatsapp: {
        icono: "fab fa-whatsapp",
        linkIcon: (
            <Link onClick={() => openInNewTab("https://api.whatsapp.com/send?phone=593990221462")}>
                <span class="fab fa-whatsapp"></span>
            </Link>),
        link: (<Link onClick={() => openInNewTab("https://api.whatsapp.com/send?phone=593990221462")}>+593 (0)99-022-1462</Link>),
        label: '+593 (0)99-022-1462'
    },
    phone1: {
        icono: 'flaticon-telephone',
        link: (<a href={"tel:" + '+593 (0)99-022-1462'}>{'+593 (0)99-022-1462'} </a>),
        label: '+593 (0)99-022-1462'
    },
    phone2: {
        icono: 'flaticon-telephone',
        link: (<a href={"tel:" + '+593 (0)99-172-7815'}>{'+593 (0)99-172-7815'} </a>),
        label: '+593 (0)99-172-7815'
    },
    email: {
        icono: 'flaticon-mail',
        link: (<a href={"mailto:" + 'intikamari@gmail.com'}>{'intikamari@gmail.com'} </a>),
        label: 'intikamari@gmail.com'
    },
    location: {
        icono: 'flaticon-location',
        link: (<ReservarLink onlyLink><>Quillosisa, Santa Isabel, <br />Azuay, Ecuador</></ReservarLink>),
        label: (<>Quillosisa, Santa Isabel, <br />Azuay, Ecuador</>)
    },
}; 