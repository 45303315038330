import ReservarLink from 'components/link/ReservarLink';
import React from 'react';

//#311703

export const RetiroTabDataSource = {
    title: 'Tipo de Retiros',
    subtitle: 'Una pausa natural',
    title2: 'Crece, Transformate, Sana',
    subtitle2: 'Una pausa natural',
    image1: require('assets/images/intiRetiro/2.png'),
    image2: require('assets/images/intiRetiro/1.png'),
    body: (<>
        Abrimos las puertas de nuestro hogar a eventos sociales, capacitaciones empresariales o retiros de bienestar.
        Somos el lugar perfecto para la creación de momentos inolvidables, la reconexión con tu equipo de trabajo o simplemente para retiros de crecimiento personal.
        Nuestra experiencia facilitará el desarrollo de estos encuentros.
    </>),
    detailCategoy: [
        {
            eventKey: 'first',
            category: 'Detalle',
            body: {
                image: require('assets/images/intiResource/5.png'),
                description:
                    <>
                        <>
                            Promovemos la realización de retiros de distintas corrientes. Extraemos nuestros conocimientos desde las rigurosas ciencias, hasta las antiguas tradiciones y religiones de todo el mundo. <br />
                            Damos apertura a disciplinas que van desde la psicología y las neurociencias hasta la meditación y el yoga. La permacultura, la nutrición, la medicina ancestral y el arte, se encuentran entre nuestro arsenal de herramientas.<br />
                            Un retiro podría ser el último empujón que necesitas, traer revelaciones inesperadas o darte la relajación más valiosa que hayas tenido en años.<br />
                        </>
                    </>,
            }
        },
        {
            eventKey: 'second',
            category: 'Organizadores',
            body: {
                image: require('assets/images/intiResource/5.png'),
                description:
                    <>
                        <>
                            Podemos darte tanta libertad como desees o tanta ayuda como requieras con la organización de tu retiro.<br />
                            Durante los últimos años, Cuenca silenciosamente se ha desarrollado para convertirse en una de las ciudades de Sudamérica más activas espiritualmente.<br />
                            Abrimos la posibilidad a organizadores y facilitadores provenientes de otros centros, para compartir propuestas y proyectos de crecimiento personal y espiritual.
                            Podemos darte tanta libertad como desees o tanta ayuda como requieras con la organización de tu retiro.
                        </>
                    </>,
            }
        },
    ],
};


export const ActividadesDataSource = {
    title: 'Crece, Transformate, Sana',
    subtitle: 'Una pausa natural',
    body: (<>
        Abrimos las puertas de nuestro hogar a eventos sociales, capacitaciones empresariales o retiros de bienestar.
        Somos el lugar perfecto para la creación de momentos inolvidables, la reconexión con tu equipo de trabajo o simplemente para retiros de crecimiento personal.
        Nuestra experiencia facilitará el desarrollo de estos encuentros.
        <br />
        <br />
        <div class="btn-box">
            <ReservarLink />
        </div>
    </>),
    detailCategoy: [
        {
            eventKey: 'third',
            category: 'Retiros de parejas, espirituales, del silencio',
            icon: 'flaticon-user',
        },
        {
            eventKey: 'first',
            category: 'Meditacion y Yoga',
            icon: 'flaticon-team',
        },
        {
            eventKey: 'fourth',
            category: 'Arte y ciencia, seminarios, trabajo en equipo',
            icon: 'flaticon-world',
        },
        {
            eventKey: 'second',
            category: 'Neurociencia y Psicologia',
            icon: 'flaticon-notebook',
        },
    ],
};

