import { Row } from 'antd';
import { ContactoDs } from 'assets/data/data.source.contacto';
import { AbautDataSource } from 'assets/data/data.source.home';
import 'assets/less/antMotionStyle.less';
import AnimationVertical from 'components/less/AnimationVertical';
import { enquireScreen } from 'enquire-js';
import React, { Component } from 'react';

const img1 = require('assets/images/intiAbaut/1.jpg');
const img2 = require('assets/images/intiAbaut/2.jpg');

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

class SectionAbaut extends Component {
    render() {
        const { name, title, subtitle1, subtitle2, description } = AbautDataSource;
        return (
            <>
                {/* <!-- About Section --> */}
                <section class="about-section">
                    <div class="home-page auto-container">
                        <div class="row align-items-center">
                            <Row>
                                <div key="ab1" class="col-md-6 col-lg-6">
                                    <div class="image-wrapper">
                                        <div class="image-one">
                                            <img width={'90%'} src={img1} alt="" />
                                        </div>
                                        <div class="image-two wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                            <div class="caption">
                                                <span class={'flaticon-location'}></span>
                                            </div>
                                            <div class="image-outer">
                                                <img width={'90%'} src={img2} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="content-box">
                                        <AnimationVertical>
                                            <div class="sec-title" key="a43">
                                                <div key="ab3" class="sub-title">{title}</div>
                                                <h2 key="ab4">{subtitle1} <br />{subtitle2}</h2>
                                                <div key="ab5" class="text">{description}</div>
                                            </div>
                                            <div key="ab12" class="row">
                                                <Row justify='center'>
                                                    <div class="info-column col-sm-6 col-md-6">
                                                        <div class="icon-box">
                                                            <div key="ab6" class="icon">
                                                                <span class='flaticon-telephone'></span>
                                                            </div>
                                                            <h5 key="ab7" >{ContactoDs.subtituloPhone}</h5>
                                                            <h2 key="ab8" >{ContactoDs.phone1.link}</h2>
                                                            <h2 key="ab8" >{ContactoDs.phone2.link}</h2>
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div class="info-column col-sm-6 col-md-6">
                                                        <div class="icon-box">
                                                            <div key="ab9" class="icon">
                                                                <span class='flaticon-mail'></span>
                                                            </div>
                                                            <h5 key="ab10" >{ContactoDs.subtituloEmail}</h5>
                                                            <h2 key="ab11" >{ContactoDs.email.link}</h2>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </AnimationVertical>
                                    </div>
                                </div>

                            </Row>
                        </div>
                    </div>
                    <br />
                </section>
            </>
        )
    }
}
export default SectionAbaut;